import React from 'react';
import PropTypes from 'prop-types';

import Header from '@moonshineragency/ui/src/components/Header/Header';

import Container from 'components/Container/Container';
import Footer from 'components/Footer/Footer';

import logo from 'images/evn-logo.png';

const Layout = ({ children, className, title, renderHeaderNav }) => {
  return (
    <div className={className}>
      <Header img={logo} title={title} renderHeaderNav={renderHeaderNav} />
      <div>
        <main>{children}</main>
      </div>
      <Container>
        <div className="text-center py-2">
          <h4 className="text-primary">
            <span className="icon phone mr-2 align-middle" />
            Wir sind gerne für Sie da.
          </h4>
          <small>
            Für alle Fragen rund um Ihren Stromanschluss steht Ihnen unsere
            Service-Hotline unter{' '}
            <a className="text-reset" href="tel:0810 800 100">
              0810 800 100
            </a>{' '}
            von Montag bis Freitag 7:00 – 17:00 zur Verfügung.
          </small>
        </div>
      </Container>
      <Footer
        items={[
          { title: 'Impressum', link: '/impressum' },
          { title: 'Kontakt', link: '/kontakt' },
          { title: 'Datenschutz', link: '/datenschutz' },
        ]}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  renderHeaderNav: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
  title: '',
  renderHeaderNav: false,
};

export default Layout;
