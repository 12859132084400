import React from 'react';
import { navigate } from 'gatsby';

import 'assets/styles/app.scss';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import { IconButton } from '@moonshineragency/ui/src/components/Button/Button';
import Badge from '@moonshineragency/ui/src/components/Badge/Badge';
import Navigation from '@moonshineragency/ui/src/components/Navigation/Navigation';

import Form, {
  Field,
  CheckboxGroup,
  SubmitButton,
} from '@moonshineragency/ui/src/components/Forms/Forms';

import Table, {
  TableRow as TR,
  TableData as TD,
} from '@moonshineragency/ui/src/components/Table/Table';

import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';

const TH = props => <TD type="th" {...props} />;

const IndexPage = () => {
  return (
    <Layout
      title="Stromanschluss Online für Elektrounternehmen"
      renderHeaderNav
    >
      <Navigation />
      <Container className="py-3">
        <Card className="mb-3" title="Kundenauftrag hinzufügen">
          <p>
            Sie haben vom Kunden eine Bearbeitungsnummer zu einem Auftrag
            erhalten?
            <br />
            Fügen Sie hier diesen Auftrag zu Ihren Aufträgen hinzu.
          </p>
          <Form
            className="d-flex align-items-baseline justify-content-center"
            onSubmit={() => navigate('/electrician/')}
          >
            <Field
              className="mr-3 text-nowrap flex-grow-1"
              name="lastname"
              type="text"
              label="Nachname des Kunden"
            />
            <Field
              className="mr-3 text-nowrap flex-grow-1"
              name="lastname"
              type="text"
              label="Bearbeitungs-Nummer"
              hint="z.B DW-12345678"
            />
            <SubmitButton
              className="align-self-center justify-content-center flex-grow-1"
              theme="primary"
            >
              Hinzufügen
            </SubmitButton>
          </Form>
        </Card>
        <Card className="mb-3" title="Meine Aufträge">
          <Form
            className="mb-3 d-flex align-items-start justify-content-between"
            onSubmit={() => navigate('/electrician/')}
            initialValues={{ completed: false }}
          >
            <div className="text-nowrap flex-grow-1">
              <CheckboxGroup
                labelClassName="font-weight-normal"
                name="completed"
                items={[
                  {
                    label: 'Auch erledige Aufträge',
                    name: 'yes',
                  },
                ]}
              />
            </div>
            <div>
              <CheckboxGroup
                type="pill"
                contentClassName="justify-content-end"
                items={[
                  {
                    name: 'alle',
                    label: 'Alle',
                  },
                  {
                    name: 'neuanschluss',
                    label: 'Neuanschluss',
                  },
                  {
                    name: 'anlagenänderung',
                    label: 'Anlagenänderung',
                  },
                  {
                    name: 'wiedereinschaltung',
                    label: 'Wiedereinschaltung',
                  },
                  {
                    name: 'erzeugungsanlage',
                    label: 'Erzeugungsanlage / Batteriespeicher',
                  },
                  {
                    name: 'fertigstellungsmeldung',
                    label: 'Fertigstellungsmeldung',
                  },
                ]}
                name="filter"
              />
            </div>
          </Form>
          <div className="table-responsive">
            <Table className="text-nowrap mb-0">
              <thead className="thead-light">
                <TR>
                  <TH />
                  <TH>Nummer</TH>
                  <TH>Geändert</TH>
                  <TH>Kunde</TH>
                  <TH>Anschlussadresse</TH>
                  <TH>Auftrag</TH>
                </TR>
              </thead>
              <tbody>
                <TR>
                  <TD className="d-flex justify-content-center">
                    <Badge theme="blue" className="font-weight-bold">
                      Neu
                    </Badge>
                  </TD>
                  <TD>DW-10912234</TD>
                  <TD>07.12.2019</TD>
                  <TD>Beatrix Herzmanonn</TD>
                  <TD>Teichmanns 2, 3623 Korneuburg</TD>
                  <TD>Neuanschluss</TD>
                </TR>
                <TR>
                  <TD />
                  <TD>DW-54534434</TD>
                  <TD>10.11.2019</TD>
                  <TD>Karl Bauer-Eder</TD>
                  <TD>Mauerbachstraße 24, 3623 Korneuburg</TD>
                  <TD>Neuanschluss</TD>
                </TR>
                <TR>
                  <TD />
                  <TD>DW-76787877</TD>
                  <TD>24.09.2019</TD>
                  <TD>Herbert Weber</TD>
                  <TD>Hofweg 15, 2400 Baden</TD>
                  <TD>Neuanschluss</TD>
                </TR>
                <TR>
                  <TD className="d-flex justify-content-center">
                    <Badge theme="secondary" className="font-weight-bold">
                      Erledigt
                    </Badge>
                  </TD>
                  <TD>DW-88976555</TD>
                  <TD>07.08.2019</TD>
                  <TD>Fred Schreyvogel</TD>
                  <TD>Einbahngasse 24, 2340 Baden</TD>
                  <TD>Erzeugungsanlage</TD>
                </TR>
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <IconButton theme="secondary" size="50">
              <span className="icon download mr-2" />
              Als CSV-Datei exportieren
            </IconButton>
          </div>
        </Card>
      </Container>
    </Layout>
  );
};

/** unrelated change to test */

export default IndexPage;
